import * as React from 'react'

import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import Layout from '../components/layout'
import Seo from '../components/seo'

import MainCTA from '../components/sections/main-cta'

const InstagramPage = () => {
  const isBrowser = () => typeof window !== 'undefined'
  if (isBrowser()) {
    window.location.href = 'https://www.instagram.com/meatifoods'
  }

  return (
    <Layout>
      <Seo title="Instagram" noindex />
      <header id="hero" className="text-start py-3 py-lg-4 py-xl-5">
        <Container>
          <h1 className="mb-1 mb-md-2 text-center">Instagram</h1>
          <p className="lead mb-md-2 text-center">
            <a
              className="btn btn-lg btn-dark me-md-12 mb-12 mb-md-0"
              href="https://www.instagram.com/meatifoods/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon className="me-12" icon={faInstagram} />
              Follow us
            </a>
          </p>
        </Container>
      </header>
      <MainCTA />
    </Layout>
  )
}

export default InstagramPage
